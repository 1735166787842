<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Events</h1>
      </div>
    </div>
    
      <section class="event-section">
        <div class="event-card">
            <img src="../assets/Event1.jpeg" alt="Career Panels">
            <h3>Career Panels</h3>
            <p>Join our M.Fin + MBA alum to learn more about your career aspirations including:</p>
            <ul>
                <li>Risk management</li>
                <li>Wealth management</li>
                <li>Credit and equity analysis</li>
                <li>Relationship banking</li>
            </ul>
        </div>

        <div class="event-card">
            <img src="../assets/Event2.jpeg" alt="Networking Socials">
            <h3>Networking Socials</h3>
            <p>Join us for professional networking every month, such as Day on Bay, our joint event with the CDC where you meet teams from various bank representatives and Schulich alum.</p>
        </div>

        <div class="event-card">
            <img src="../assets/Event3.jpeg" alt="Case Competitions">
            <h3>Case Competitions</h3>
            <p>Stay tuned for Stock Pitch and StockTrak competitions in the fall and winter terms!</p>
        </div>
    </section>
    <div class="past-events">
      <h1 style="font-size: 40px; box-shadow: 0px 2px #0056b3;">Past Events</h1>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="../assets/SFA Event5.jpeg" alt="Avatar" >
          </div>
          <div class="flip-card-back">
            <h1>How to Approach Your Stock Pitch</h1>
            <p>Friday, November 29</p>
            <p>Is there a company you've been begging your friends and family to follow? Have you been checking its share price every morning before you even have your coffee? Look no further! Lean how to conduct an equity research analysis and creation of the stock pitch</p>
          </div>
        </div>
      </div>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="../assets/SFA Event4.jpeg" alt="Avatar" >
          </div>
          <div class="flip-card-back">
            <h1>Finance in Case Competition</h1>
            <p>Friday, November 22</p>
            <p>Explore essential skills and frameworks for finance case competitions, including key analysis techniques, investment assessment, and financial modeling to navigate high-stakes scenarios with confidence.</p>
          </div>
        </div>
      </div>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="../assets/SFA Event3.jpeg" alt="Avatar" >
          </div>
          <div class="flip-card-back">
            <h1>Investments in Exploration Companies</h1>
            <p>Friday, November 15</p>
            <p>Evaluating investment criteria and considerations in exploration companies.
              Agenda:
              1. Understanding Exploration Companies - technical details.
              2. Understanding mineral resources and reserves
              3. How to evaluate these companies?
              4. Live examples and run through of analysis.
              5. Share structure & performance.
              6. Case studies (2).
            </p>
          </div>
        </div>
      </div>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="../assets/SFA event 2.jpg" alt="Avatar" style="width: 100%;" >
          </div>
          <div class="flip-card-back">
            <h1>What Even Is Finance? Exploring the Fields and Careers That Make Up the Financial World</h1>
            <p>Tuesday, October 8</p>
            <p>Ever found out that someone works in "finance" and realized that you still had no idea what they do?
            Join us for a conversation with experts across the various different fields of finance, and learn about all that the field has to offer for your future career. From corporate banking to equity research and beyond, this event is for you!
            </p>
          </div>
        </div>
      </div>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="../assets/SFA Event1.jpeg" alt="Avatar" >
          </div>
          <div class="flip-card-back">
            <h1>THE HEATHER L. MAIN SCHOLORSHIP</h1>
            <p>Thursday, August 29</p>
            <p>Get application insights, tips from past recipients, and guidance from CCD, WIL, and SFA.
            Discover all the information you need, to apply for this amazing opportunity!
            </p>
          </div>
        </div>
      </div>
    </div>


</template>
<script>
</script>
<style lang="scss" scoped>
.background {
    background: linear-gradient(132deg, #0056b3, #000000, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

.past-events{
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    height: 100vh;
    flex-wrap: wrap;
}

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.event-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.event-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    text-align: center;
    padding: 20px;
}

.event-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
}

.event-card h3 {
    font-size: 20px;
    margin: 15px 0;
    color: #333;
}

.event-card p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    text-align: left;
}

.event-card ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

.event-card ul li {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.event-card:hover {
    transform: translateY(-5px);
}
.flip-card {
  background-color: transparent;
  width: 30%;
  height: 300px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
}
.flip-card-front img{
  height: 100%;
}

/* Style the back side */
.flip-card-back {
  color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(45deg, #0056b3, #000000);
    justify-content: space-evenly;
    padding: 10px;
}
@media (max-width: 992px) {
  .past-events{
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    height: auto;
  }
  .flip-card{
    width: 70%;
    margin: 3px;
  }
  .flip-card-back{
    padding: 0;
  }
  .flip-card-back h1{
    font-size: 1rem;
  }
  .flip-card-back p{
    font-size: 0.8rem;
  }
}
</style>