<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center; flex-direction: column;">
            <h1 style="font-size: 3em; color: #fff;" >Case Competition</h1>
            
            <div style="display: flex; width: auto; justify-content: flex-start;">
                <div style="margin-right: 10%;">
                    
                    <img src="../assets/SFA_LOGO.png" style="height: 100px;" alt="">
                </div>
                <div style="color: #fff; font-size: x-large; display: flex; align-items: center;">
                    <img src="../assets/fmp-brain-original.svg" style="height: 100px; margin-right: 5px;" alt=""> Financial Modeling Prep
                </div>
                
            </div>
      </div>
   </div>
   <div class="container">
    <h2>About the Event</h2>
    <p>The <strong>Day on Bay Case Competition</strong> is a premier event for all graduate students passionate about finance, strategy, and consulting. Hosted by the <strong>Schulich Finance Association (SFA)</strong> in collaboration with <strong>Financial Modeling Prep</strong>, this competition provides a unique platform for participants to showcase their analytical and problem-solving skills by tackling real-world challenges in the domain of <strong>Mergers & Acquisitions (M&A)</strong>. Participants will have the opportunity to engage with industry experts, gain valuable feedback, and network with some of the brightest minds in the field.</p>

    <h2>Event Details</h2>
    <ul>
        <li><strong>Date:</strong> January 30th, 2025</li>
        <li><strong>Time:</strong> 9:00 AM to 1:30 PM</li>
        <li><strong>Location:</strong> 325 Front Street West, Toronto</li>
    </ul>

    <h2>Competition Timeline</h2>
    <ul>
        <li><strong>Launch Date:</strong> January 17th, 2025</li>
        <li><strong>Final Submission Deadline:</strong> January 27th, 2025</li>
    </ul>
    
    <h2>Why Join?</h2>
    <ul>
        <li>Showcase your <strong>creativity and analytical thinking</strong> by solving a real-world business challenge.</li>
        <li>Gain hands-on experience in <strong>M&A strategies</strong>, including deal structuring, valuation, and risk assessment.</li>
        <li>Learn from and connect with industry experts, mentors, and fellow participants to enhance your <strong>professional network</strong>.</li>
        <li>Improve your <strong>public speaking and presentation skills</strong> by presenting your recommendations to a panel of judges.</li>
        <li>Compete for <strong>cash prizes and recognition</strong>, which can bolster your resume and career prospects.</li>
    </ul>

    <h2>Key Details</h2>
    <ul>
        <li><strong>Team Size:</strong> Teams should consist of <Strong>3-4 members</Strong>.</li>
        <li><strong>Prizes:</strong> Compete for cash prizes of <Strong>$500, $200, and $100</Strong>, along with the chance to impress industry leaders and showcase your expertise.</li>
        <li><strong>Registration:</strong> All participants must purchase a Day on Bay ticket and pay a case competition fee. Registration details will be provided upon ticket purchase.</li>
        <li><strong>Results:</strong> Teams selected to present at the Day on Bay event will be notified on <strong>January 29th, 2025</strong>.</li>
    </ul>

    <h2>FAQs</h2>
    <h3>Who can participate?</h3>
    <p>The competition is open to all graduate students. Whether you are new to M&A or have prior experience, this event offers valuable learning opportunities for all skill levels.</p>

    <h3>How to Register?</h3>
    <p>To participate in the competition, follow the registration process outlined on the event page. Ensure that your team is formed prior to registration. Each team member must complete the registration to confirm their spot in the competition.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about the competition or the registration process, please feel free to reach out to us at <strong>sfa@schulich.yorku.ca</strong>. We look forward to seeing you at the event and wish you the best of luck in the case competition!</p>
    <!-- <div class="download-button">
        <a href="/mnt/data/image.png" download><i class="fas fa-download"></i> Download Case Competition Details</a>
    </div> -->
</div>

</template>
<script>

</script>
<style scoped>
.background {
    background: linear-gradient(132deg, #0056b3, #000000, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
    .event-details {
      padding: 20px;
      max-width: 800px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
      line-height: 1.6;
    }
    
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    
    h3 {
      margin-top: 10px;
    }
    
    ul {
      list-style: none;
      padding: 0;
    }
    
    li {
      margin: 5px 0;
    }
    
    .register-button {
      padding: 15px 30px; background-image:linear-gradient(45deg, #0056b3, #000000); color: white; text-decoration: none; font-size: 1.2em; border-radius: 5px;
      cursor: pointer;
    }
    
    .register-button:hover {
      background-color: #0056b3;
    }
    video {
    width: 100%;
    max-width: 800px;
    height: auto;
    margin: 20px auto;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
    outline: none;
  }
  .container {
            max-width: 70%;
            margin: 20px auto;
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        h1, h2, h3 {
            color: #001f3f;
        }
        ul {
            list-style-type: square;
            padding-left: 20px;
        }
        p {
            line-height: 1.6;
        }
        .download-button {
            display: block;
            text-align: center;
            margin: 20px 0;
        }
        .download-button a {
            text-decoration: none;
            color: white;
            background-color: #007bff;
            padding: 15px 30px;
            border-radius: 5px;
            font-size: 18px;
        }
        .download-button a:hover {
            background-color: #0056b3;
        }
        h2{
            margin-top: 10px;
        }
    </style>