<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Day On Bay + Case Competition</h1>
      </div>
   </div>
   <div>
    <video src="../assets/DOB video.mp4" autoplay muted loop playsinline></video>
   </div>
   <div class="event-details">
    <p>
        <strong>The ultimate finance networking event of the year</strong>, our flagship event – <strong>Day on Bay</strong> – is finally here! Join us for a morning in the heart of downtown Toronto's financial district and meet dozens of industry professionals working in every area of finance imaginable!
        Hear from a panel of <strong>expert executives</strong> and ask them your burning finance & career questions, followed by a networking session with an almost <strong>1:1 ratio</strong> of students to professionals (many of whom are Schulich alumni)!
        <br>
        <br>
        This year, we will even be launching a <strong>case competition</strong> to be worked on in advance of the big day. Finalists will have an opportunity to present in front of the experts on the panel.
        <br>
        <br>
        Last year, Day on Bay registration sold out quickly and there was a long waiting list. Don't miss out, and don't say we didn't warn you! Register early!
      </p>
    <!-- <p>
    <strong>The ultimate finance networking event of the year</strong>, our flagship event – <strong>Day on Bay</strong> – is finally here! 
    Join us for a morning in the heart of downtown Toronto's financial district and connect with <strong>dozens of industry professionals</strong> working in every area of finance imaginable.
  
    </p>
    <br>
  <p>
    <strong>Highlights of the Event:</strong>
    <ul>
      <li>Engage with a panel of <strong>expert executives</strong> and ask them your burning finance and career questions.</li>
      <li>Enjoy a networking session with an almost <strong>1:1 ratio</strong> of students to professionals, many of whom are Schulich alumni.</li>
    </ul>
  </p>
  <br>
  <p>
    <strong>New This Year:</strong> We are excited to announce the launch of a <strong>case competition</strong> that participants will work on in advance of the big day. Finalists will have the exclusive opportunity to present their ideas in front of the expert panel.
  </p>
  <br>
  <p>
    Last year, <strong>Day on Bay registration sold out quickly</strong>, with a long waiting list. Don't miss out this year—<strong>register early</strong> to secure your spot!
  </p>
  <br> -->
      <h3>Details</h3>
      <ul>
        <li><strong>Breakfast and Lunch Included</strong></li>
        <li><div><strong>Location:</strong> 325 Front Street West, Toronto, ON M5V 2Y1, Canada</div>
            <div><iframe
               width="100%"
               height="300px"
               style="border:0"
               loading="lazy"
               allowfullscreen
               referrerpolicy="no-referrer-when-downgrade"
               src="https://maps.google.com/maps?f=q&source=s_q&hl=en&q=325 Front Street West, Toronto, ON M5V 2Y1&ie=UTF8&output=embed&iwloc=near">
              </iframe>
            </div>
        </li>
        <li><strong>Date:</strong> 30th January, 2025</li>
        <li><strong>Time:</strong> 9:00 AM - 1:30 PM EST</li>
      </ul>
      <p style="margin-bottom: 20px;">
        <strong>Note:</strong> Participation in the case competition requires purchasing either a 'Day On Bay' member or non-member ticket, depending on your membership status. Ensure you secure your ticket to be eligible for the competition.
      </p>
      <a href="https://day-on-bay-case-competition-40747.cheddarup.com" class="register-button" >Register Now</a>
   </div>

</template>
<script>

</script>
<style scoped>
.background {
    background: linear-gradient(132deg, #0056b3, #000000, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
    .event-details {
      padding: 20px;
      max-width: 800px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
      line-height: 1.6;
    }
    
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    
    h3 {
      margin-top: 20px;
    }
    
    ul {
      list-style: none;
      padding: 0;
    }
    
    li {
      margin: 5px 0;
    }
    
    .register-button {
      padding: 15px 30px; background-image:linear-gradient(45deg, #0056b3, #000000); color: white; text-decoration: none; font-size: 1.2em; border-radius: 5px;
      cursor: pointer;
    }
    
    .register-button:hover {
      background-color: #0056b3;
    }
    video {
    width: 100%;
    max-width: 800px;
    height: auto;
    margin: 20px auto;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
    outline: none;
  }
    </style>