<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Newsletters</h1>
      </div>
   </div>
   <div class="newsletter-container">
        <div class="newsletter-item">
            <img src="../assets/newsletter27.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>The upcoming Natural Gas Opportunity</h2>
                <a href="https://www.linkedin.com/pulse/upcoming-natural-gas-opportunity-vincent-fy6gc/?trackingId=p4B8UmnCRwewAGAeDx1opQ%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter26.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-1hnuc/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter25.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>The Evolution of Algorithmic Trading: A Century of Transformation in Financial Markets</h2>
                <a href="https://www.linkedin.com/pulse/evolution-algorithmic-trading-century-transformation-wgsyc/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter24.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-jvmqc/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter23.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Solving Canada’s Housing Crisis: Practical Solutions for a Better Future</h2>
                <a href="https://www.linkedin.com/pulse/solving-canadas-housing-crisis-practical-liedc/" class="btn">Read More</a>
            </div>
        </div>         
        <div class="newsletter-item">
            <img src="../assets/newsletter22.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-kfsnc/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter21.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Green Investing: Making Your Money Work for the Planet</h2>
                <a href="https://www.linkedin.com/pulse/green-investing-making-your-money-work-planet-e6ruc/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter20.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Trump Returns to White House with 2024 Victory, Unleashing Market Surge and Global Reaction</h2>
                <a href="https://www.linkedin.com/pulse/trump-returns-white-house-2024-victory-unleashing-visac/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter19.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-3ac7c/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter18.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Beyond Buildings: How REITs Are Reshaping the Investment Landscape for Infrastructure Assets</h2>
                <a href="https://www.linkedin.com/pulse/beyond-buildings-how-reits-reshaping-investment-zy3nc/" class="btn">Read More</a>
            </div>
        </div>     
        <div class="newsletter-item">
            <img src="../assets/newsletter17.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Why I Am Bullish on the ETF SOXX</h2>
                <a href="https://www.linkedin.com/pulse/why-i-am-bullish-etf-soxx-schulich-finance-association-q6tdc/" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter16.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-fufkc/?trackingId=xPO6QY3wRRa24YvxR%2BBM7g%3D%3D" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter15.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Lithium: A Deep Dive</h2>
                <a href="https://www.linkedin.com/pulse/lithium-deep-dive-schulich-finance-association-dipic/?trackingId=9e%2BfXcufTAC295XPW1bB5g%3D%3D" class="btn">Read More</a>
            </div>
        </div> 
        <div class="newsletter-item">
            <img src="../assets/newsletter14.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Cash Futures Basis Trade in the Government of Canada Bond Market</h2>
                <a href="https://www.linkedin.com/pulse/cash-futures-basis-trade-government-canada-9w0gc/?trackingId=Tjj%2BaU4sToaxNch4%2Fh%2F1CA%3D%3D" class="btn">Read More</a>
            </div>
        </div> 
        
        <div class="newsletter-item">
            <img src="../assets/newsletter13.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-jtzxc/?trackingId=7t3WlEKOS4WArus2bhrKzg%3D%3D" class="btn">Read More</a>
            </div>
        </div>  
        
        <div class="newsletter-item">
            <img src="../assets/newsletter12.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Mutual Funds: Your Expert-Managed Path to Financial Growth and Security</h2>
                <a href="https://www.linkedin.com/pulse/mutual-funds-your-expert-managed-path-financial-jjhbc/?trackingId=G%2BIcsoFAT4C1FB9VxiD2Og%3D%3D" class="btn">Read More</a>
            </div>
        </div>  
        <div class="newsletter-item">
            <img src="../assets/newsletter11.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Weekly Market Update</h2>
                <a href="https://www.linkedin.com/pulse/weekly-market-update-schulich-finance-association-xwcnc/?trackingId=E50zJMnqS3Ggn9k1thBwzA%3D%3D" class="btn">Read More</a>
            </div>
        </div>  
        <div class="newsletter-item">
            <img src="../assets/newsletter10.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>CBDCs: The Power and Influence of Currency</h2>
                <a href="https://www.linkedin.com/pulse/cbdcs-power-influence-currency-schulich-finance-association-6p9wc/?trackingId=yI4E7MVBQMq9AO4YNKhsnQ%3D%3D" class="btn">Read More</a>
            </div>
        </div>  
        <div class="newsletter-item">
            <img src="../assets/newsletter9.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Lessons in Personal Finance - Part 2</h2>
                <a href="https://www.linkedin.com/pulse/lessons-personal-finance-part-2-schulich-finance-association-bg23c/?trackingId=REjjvVHWSj223BMPIaPhoQ%3D%3D" class="btn">Read More</a>
            </div>
        </div>      

        <div class="newsletter-item">
            <img src="../assets/newsletter8.png" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Welcome New SFA Members!</h2>
                <a href="https://www.linkedin.com/pulse/capital-currents-welcome-new-sfa-members-6xdvc/?trackingId=fDnBRXzGTHWWm78vm0CpTQ%3D%3D" class="btn">Read More</a>
            </div>
        </div>    

        <div class="newsletter-item">
            <img src="../assets/newsletter7.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Is the IRA Here to Stay, or On Borrowed Time?</h2>
                <a href="https://www.linkedin.com/pulse/ira-here-stay-borrowed-time-schulich-finance-association-r1wac/?trackingId=Rd%2FFmYV4SDKI4RwylT9U0A%3D%3D" class="btn">Read More</a>
            </div>
        </div>

        <div class="newsletter-item">
            <img src="../assets/newsletter6.jpeg" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Market Update</h2>
                <a href="https://www.linkedin.com/pulse/market-update-schulich-finance-association-xgdlc/?trackingId=K2FJ5q9%2BTQ60rnWJVPemTw%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter5.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Navigating Steady Rates and Market Ripples</h2>
                <a href="https://www.linkedin.com/pulse/navigating-steady-rates-market-ripples-schulich-finance-association-sswec/?trackingId=rtBQgDCfQNW4Q%2FTOUlciIA%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter4.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>BoC: Two Cuts Done, More in 2024?</h2>
                <a href="https://www.linkedin.com/pulse/boc-two-cuts-done-more-in2024-schulich-finance-association-6dv6c/?trackingId=DYopl4SfS%2B2us0vtReztMQ%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter3.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Canada's Interest Rate Conundrum: Balancing Inflation and Economic Growth</h2>
                <a href="https://www.linkedin.com/pulse/canadas-interest-rate-conundrum-balancing-v48kc/?trackingId=5ARQEWCYSWO%2FZrI4JWZ9Mw%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter2.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Politics: Hotter over the Weekend!</h2>
                <a href="https://www.linkedin.com/pulse/politics-hotter-over-weekend-schulich-finance-association-wentc/?trackingId=MiCI1rJ1QAe87%2BndmpbG2w%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter1.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Introducing Capital Currents by SFA</h2>
                <a href="https://www.linkedin.com/pulse/introducing-capital-currents-sfa-schulich-finance-association-n2smc/?trackingId=FQUulBfBSEq6RhmyU%2BAY1Q%3D%3D" class="btn">Read More</a>
            </div>
        </div>

        <!-- Add more newsletter items as needed -->

    </div>

</template>
<script>
</script>
<style lang="scss" scoped>
.background {
    background: linear-gradient(132deg, #0056b3, #000000, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }



  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.newsletter-container {
    width: 100%;
    padding: 20px 0 20px 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-image: linear-gradient(180deg, transparent, #0056b3, transparent);
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.newsletter-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  
}

.newsletter-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 30%;
    flex-direction: column;
    max-height: 415px;
    height: 415px;
}

.newsletter-item:hover {
    transform: translateY(-5px);
}

.newsletter-item img {
    width: 100%;
    object-fit: fill;
    height: 220px;
}

.newsletter-content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.newsletter-content h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.newsletter-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
}

.btn {
    padding: 10px 20px;
    background-image: linear-gradient(45deg, #0056b3, #000000);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 16px;
    display: inline-block;
    width: fit-content;
}

.btn:hover {
    background-color: #0056b3;
}

@media (max-width: 992px) {
    .newsletter-container{
        flex-direction: column;

    }
    .newsletter-item{
        width: 80%;

    }
    .newsletter-content h2{
        font-size: 1rem;
    }

}
</style>