
<template>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-container">
      <button class="close-button" @click="closePopup">×</button>
      <!-- <img src="../assets/newsletter8.png" alt="Day on Bay Event Banner" class="popup-banner" /> -->
      <video src="../assets/DOB video.mp4" autoplay muted loop playsinline class="popup-banner"></video>
      <h2>Day On Bay + Case Competition</h2>
      <p>Don't miss the ultimate finance networking event of the year!</p>
      <div class="popup-buttons">
        <a href="https://day-on-bay-case-competition-40747.cheddarup.com" class="register-button">Register</a>
        <router-link to="/day-on-bay" class="info-button" >View More Information</router-link>
      </div>
    </div>
  </div>
  <div class="home">
    <transition-group name="fade">
    <div class="hero-section" style=" background-size: cover; background-position: center; height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; color: white;">
      <h1 style="font-size: 3em; font-weight: bold;">Schulich Finance Association</h1>
      <p style="font-size: 1.5em; margin: 20px 0;padding: 20px;">SFA is here to assist our members prepare for a successful career in Finance via knowledge-sharing, events and the building of a global professional network.</p>
      <a href="https://my.cheddarup.com/c/sfa-memberships-2024-26/items?cart" style="padding: 15px 30px; background-image:linear-gradient(45deg, #0056b3, #000000); color: white; text-decoration: none; font-size: 1.2em; border-radius: 5px;">Become a Member</a>
    </div>
  </transition-group>
    <div class="features-section">
      <div class="feature-box">
          <h3>Events & Activities</h3>
          <p>The SFA is inviting you to many interactive events and activities to help you to gain some real-world experience during your academic life.</p>
      </div>
      <div class="feature-box">
          <h3>Exclusive Resources</h3>
          <p>By accessing the member-exclusive resources, you will further sharpen your knowledge and learn how to pursue a career in the finance industry.</p>
      </div>
      <div class="feature-box">
          <h3>Connections</h3>
          <p>We aim at providing opportunities to students and a platform to reach out and network with successful finance professionals across Canada.</p>
      </div>
  </div>
  <div class="content-section">
    <div class="image-container">
        <img src="../assets/Member_img.png" alt="Team meeting" />
    </div>
    <div class="text-container">
        <h2>The Schulich Finance Association is committed to expanding the competence of our members while creating opportunities to make professional connections.</h2>
        <div class="highlight-box">
            <p>Members will have access to year-round events & activities as well as exclusive resources. Most importantly, you will be a part of an elite group of like-minded people and connected to many professionals in the finance industry.</p>
        </div>
    </div>
</div>
<div>
  <h1 class="plan-heading">Your ticket to invaluable insight and experience</h1>

<div class="plans">
    <div class="plan columns">
        <h2>1-Year Subscription</h2>
        <div class="price">$35.00</div>
        <p>CAD, does not include fees</p>
        <hr>
        <ul>
            <li>Access to year-round events</li>
            <li>Exclusive entry to activities</li>
            <li>Stock insights and newsletters</li>
            <li>Professional networking</li>
            <li>Discounted access to Day on Bay</li>
        </ul>
        <a href="https://my.cheddarup.com/c/sfa-memberships-2024-26/items?cart" class="button">Become a Member</a>
    </div>

    <div class="plan columns">
        <h2>2-Year Subscription</h2>
        <div class="price">$55.00</div>
        <p>CAD, does not include fees</p>
        <hr>
        <ul>
            <li>Access to year-round events</li>
            <li>Exclusive entry to activities</li>
            <li>Stock insights and newsletters</li>
            <li>Professional networking</li>
            <li>Discounted access to Day on Bay</li>
            <li>Discount on select GBC events</li>
        </ul>
        <a href="https://my.cheddarup.com/c/sfa-memberships-2024-26/items?cart" class="button">Become a Member</a>
    </div>
</div>

</div>

<div class="container">
    <div class="left-section">
        <h2>Schulich Finance Association</h2>
        <div class="contact-info">
            <h3>Contact Us</h3>
            <p><a href="mailto:sfa@schulich.yorku.ca" style="color: white; text-decoration: none;">sfa@schulich.yorku.ca</a></p>
            <div class="social-icons">
                <a href="https://www.instagram.com/sfa_schulich/"><img src="../assets/instagram.svg"  alt="instagram"></a>
                <a href="https://www.linkedin.com/company/schulich-finance-association/"><img src="../assets/linkedln.svg" alt="linkedln"></a>
            </div>
        </div>
        <div class="location-info">
            <h3>Our Location</h3>
            <p>111 Ian<br>
               MacDonald Blvd<br>
               Toronto, ON,<br>
               M3J 1P3</p>
        </div>
    </div>
    <div class="right-section">
        <p>Thank you for your interest in the Schulich Finance Association (SFA). We're here to help with any questions you may have.</p>
        <div class="contact-container">
        <form class="contact-form" action="#" method="POST">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" name="name" v-model="name" required>
                <p v-if="error.name">{{ error.name }}</p>
            </div>
            <div class="form-group">
                <label for="email">Email Address</label>
                <input type="email" id="email" name="email" v-model="email" required>
                <p v-if="error.email">{{ error.email }}</p>
            </div>
            <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" name="message" rows="5" v-model="message" required></textarea>
                <p v-if="error.message">{{ error.message }}</p>
            </div>
            <button type="submit" @click="submitHandler">Submit</button>
        </form>
    </div>
    </div>
</div>



  </div>
</template>

<script>
import axios from "axios";
import { instance } from "../api";
export default {
  name: "Home",
  components: {},
  data(){
    return{
      name:"",
      email:"",
      message:"",
      error:{
        name:"",
        email:"",
        message:""
      },
      showPopup: true,
    };
  },
  mounted(){ 
   
      
      
  },
  methods:{
    async submitHandler(){
      const formValidation = this.validateForm();
      if (!formValidation) {
        return;
      }
      this.sendEmail();
      this.clearForm();

    },
    validateForm() {
      // validate blanks
      let formStatus = true;
      if (this.name.length === 0) {
        this.error.name = "Name cannot be empty";
        formStatus = false;
      }
      if (this.email.length === 0) {
        this.error.email = "Email cannot be empty";
        formStatus = false;
      }
      if (this.message === 0) {
        this.error.message = "Message cannot be empty";
        formStatus = false;
      }
      
      return formStatus;
    },
    clearForm() {
      this.name = "";
      this.email = "";
      this.message = "";
    },
    async sendEmail() {
      try {
        const response = await instance.post('/send-email', {
          recipients: this.email,
          subject: "Contact Us",
          text: this.message
        });
        alert(response.data.message);
      } catch (error) {
        alert('Failed to send email');
      }
    },
    closePopup() {
      this.showPopup = false;
    },

  }
};

</script>

<style lang="scss" scoped>
.home {
  position: relative;
  .information {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    span {
      font-size: 5.2rem;
      font-weight: bold;
      line-height: 1.2;
    }
    a {
      color: #494949;
      margin-top: 30px;
      padding: 10px 10px;
      width: 200px;
      font-size: 20px;
      border-radius: 30px;
      border: 0;
      background-color: #f6c467;
      text-decoration: none;
      text-align: center;
      transition: all 0.2s ease;
      &:hover {
        background-color: #f7ac21;
        transform: scale(1.02);
      }
    }
  }
  #lottie {
    position: absolute;
    z-index: 0;
    left: 100%;
    top: 50%;
    transform: translate(-100%, -50%);
    width: 600px;
    height: 600px;
  }
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-leave-to {
  transform: translateX(-80%);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease-in;
}
.fade-leave-active {
  transition: all 0.4s linear;
}
.hero-section {
    background-image: url('../assets/Hero_BG.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.hero-section h1 {
    font-size: 3em;
    font-weight: bold;
    text-shadow: 2px 2px 4px #000000;
}

.hero-section p {
    font-size: 2em;
    margin: 20px 0;
    text-shadow: 2px 2px 4px #000000;
}

.hero-section a {
    padding: 15px 30px;
    background-color: #0056b3;
    color: white;
    text-decoration: none;
    font-size: 1.2em;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}
.hero-section a:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.4)
}
.features-section {
    display: flex;
    justify-content: space-between;
    padding: 55px;
    background-color: #f8f9fa;
}

.feature-box {
    flex: 1;
    margin: 0 10px;
    padding: 20px;
    background-image: linear-gradient(45deg,#0056b3,#000);
    color: white;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    box-shadow: 10px 10px black;
}

.feature-box h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.feature-box p {
    font-size: 1em;
}

.feature-box:hover {
    transform: translateY(-5px);
}

@media (max-width: 992px) {
    .features-section {
        flex-direction: column;
    }
    .feature-box {
        margin: 10px 0;
    }
}
.content-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px;
    background-color: #ffffff;
    width: 100%;
    flex-wrap: wrap;
}

.image-container{
  width: 50%;
  display: flex;
  justify-content: center;
}
.image-container img {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    box-shadow: 10px 10px #0056b3;
}

.text-container {
    flex: 1;
    padding-left: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: start;
}

.text-container h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
    width: 60%;
}

.highlight-box {
    background-image: linear-gradient(45deg,#0056b3,#000);
    padding: 20px;
    border-radius: 10px;
    color: white;
    width: 60%;
    box-shadow: 10px 10px black;

}

.highlight-box p {
    margin: 0;
    font-size: 1.1em;
}

@media (max-width: 992px) {
    .content-section {
        flex-direction: column;
        text-align: center;
        width: 100%;
    }

    .image-container {
        margin-bottom: 20px;
        width: 100%;
    }

    .text-container {
        padding-left: 0;
        width: 100%;
        align-items: start;
        text-align: left;
    }
}
.plan-heading {
            margin-top: 50px;
            color: #333;
            margin-bottom: 50px;
            font-size: 3em;
            text-align: center;
        }

        .plans {
            display: flex;
            justify-content: space-evenly;
            margin-top: 30px;
            padding: 30px;
            flex-wrap: wrap;
        }

        .plan {
            background-color: white;
            border: 1px solid #ddd;
            border-radius: 8px;
            margin: 0 15px;
            padding: 20px;
            width: 300px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .plan h2 {
            margin-top: 0;
            color: #333;
        }

        .price {
            font-size: 24px;
            color: #0073e6;
        }

        .plan ul {
            list-style: none;
            padding: 0;
            margin-left: 25%;
        }

        .plan ul li {
            margin: 10px 0;
            text-align: left;
            padding-left: 25px;
            position: relative;
            color: #555;
        }

        .plan ul li::before {
            content: '✔';
            color: #0073e6;
            position: absolute;
            left: 0;
            top: 2px;
        }

        .button {
            display: inline-block;
            padding: 10px 25px;
            margin-top: 20px;
            color: white;
            background-image: linear-gradient(45deg, #0056b3, #000000);
            text-decoration: none;
            border-radius: 5px;
        }
  
        .container {
            display: flex;
            justify-content: center;
            padding: 50px;
            background-color: #000000;
            color: #ffffff;
            margin: 0 auto;
            flex-wrap: wrap;
            align-items: center;
        }

        .left-section {
            text-align: left;
            max-width: 300px;
        }

        .left-section h2 {
            font-family: 'Georgia', serif;
            font-size: 24px;
            margin-bottom: 20px;
        }

        .left-section p {
            margin: 0;
            line-height: 1.5;
        }

        .left-section .social-icons img {
            width: 24px;
            margin: 10px;
            background-color: #fff;

        }

        .right-section {
            text-align: left;
            max-width: 600px;
        }

        .right-section p {
            font-size: 18px;
            line-height: 1.6;
        }

        .contact-info {
            margin-bottom: 30px;
        }

        .contact-info h3 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .location-info h3 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .columns {
          float: left;
          width: 33.3%;
          padding: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          
        }
        .columns:hover {
          box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
        }
        .button:hover {
          box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
        }

  @media only screen and (max-width: 992px) {
  .columns {
    width: 100%;
  }
  .contact-container {
    width: 280px !important;
  }
  .right-section{
    max-width: 100%;
  }
  .right-section p{
    width:85%;
  }
}
.contact-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin-top: 10px;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-size: 14px;
    color: #555;
}

.form-group input, 
.form-group textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}

button {
    padding: 10px 20px;
    background-image: linear-gradient(45deg,#0056b3,#000);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

button:hover {
    background-color: #0056b3;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-banner {
  width: 100%;
  border-radius: 10px;
}

.popup-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.register-button,
.info-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: white;
  width: fit-content;
  text-decoration: none;
}

.register-button {
  background-image: linear-gradient(45deg,#0056b3,#000);
}

.info-button {
  background-image: linear-gradient(45deg,#0056b3,#000);
}

.register-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  margin-bottom: 10px;
}

.close-button:hover {
  color: red;
}

</style>
